import { useState } from "react";
import StlFormContainer from "components/StlFormContainer";
import "./stlQuery.scss";
import LoadingScreen from "components/LoadingScreen";
import { authRouteStore } from "api/auth/userStore";

function STLQuery({
  step,
  steps,
  title,
  className,
  innerClassName,
  innerInnerClassName,
}: {
  step: Step;
  steps: Steps;
  title?: string;
  className?: string;
  innerClassName?: string;
  innerInnerClassName?: string;
}) {
  const StepView: any = steps[step];
  return (
    //@ts-ignore
    <StlFormContainer
      className={`STL_query_container ${className}`}
      innerClassName={`STL_query_inner_container ${innerClassName}`}
      title={title ? title : "Query"}
    >
      <div className={`STL_query_inner_inner_container ${innerInnerClassName}`}>
        {step !== "loading" ? <StepView /> : <Loading />}
      </div>
    </StlFormContainer>
  );
}

export default STLQuery;

export type Step = "query" | "loading" | "success" | "failure";

type Steps = {
  query: Function;
  loading?: Function;
  success: Function;
  failure: Function;
};

function Loading() {
  return (
    <LoadingScreen
      message="Fetching online intake information"
      className={`loading_style`}
    />
  );
}
