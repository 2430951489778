import { useState } from "react";
import LoadingScreen from "components/LoadingScreen";
import { authRouteStore } from "api/auth/userStore";
import STLQuery from "components/STLQuery";

function QueryReimbursement({ title }: any) {
  const [step, setStep] = useState("query");
  const steps: any = {
    query: Query,
    success: Success,
    failure: Failure,
  };

  const [entries, setEntries] = useState([]);

  let authRoute = authRouteStore.getState();

  return (
    //@ts-ignore
    <STLQuery title={title ? title : "Query"} step={step} steps={steps} />
  );

  function Query() {
    const [password, setPassword] = useState("");
    return (
      <>
        <label htmlFor="requestor" className="mt-4">
          Requestor
          <input
            type="text"
            name="Requestor"
            id="requestor"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <label htmlFor="password">
          Password
          <input
            type="password"
            name="password"
            id="password"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>

        <button type="submit" onClick={onQueryIntake}>
          Query Reimbursement
        </button>
      </>
    );
    function onQueryIntake() {
      setStep("loading");
      const body = JSON.stringify({
        password,
      });

      fetch(`${authRoute}/reimbursement/query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      })
        .then(async (res) => {
          if (!res.ok) {
            return Promise.reject(await res.json());
          }
          return res.json();
        })
        .then((entries) => {
          console.log("entries:", entries);
          setEntries(entries);
          setStep("success");
        })
        .catch((err) => {
          console.log("err:", err);
          let { message } = err;
          message = message ? message : "An error occurred";
          setStep("failure");
          alert(message);
        });
    }
  }

  function Success() {
    return (
      <>
        <BackButton />
        <h2 className="status">
          Query: <span className="success">Successful</span>
        </h2>
        {entries ? (
          entries.map((entry, i) => <Entry entry={entry} key={i} />)
        ) : (
          <></>
        )}
      </>
    );

    function Entry({ entry }: any) {
      const [loading, setLoading] = useState(false);
      return (
        <div className={`entry ${loading ? `is_loading` : ``}`}>
          <h3>
            Requester: <br />
            {entry.requester}
          </h3>
          <p>Date: {formatDate(entry.date)}</p>
          {!loading ? (
            <button onClick={onDownloadEntry}>Download Entry</button>
          ) : (
            <LoadingScreen
              message="Generating Entry PDF"
              className={`loading_style`}
            />
          )}
        </div>
      );

      function onDownloadEntry() {
        setLoading(true);
        // Fetch paperwork /reimbursement/query
        fetch(`${authRoute}/reimbursement/download`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(entry),
        })
          .then(async (response) => {
            if (!response.ok) {
              return Promise.reject(await response.text());
            }
            return response.blob(); // Return the response as a Blob
          })
          .then((blob: any) => {});
      }
    }
  }

  function Failure() {
    return (
      <>
        <BackButton />
        <h2 className="status">
          Query: <span className="failure">Failed</span>
        </h2>
        <p>
          Either an error occurred on our end or your query produced no results
        </p>
      </>
    );
  }

  function BackButton() {
    return (
      <button className="back_button" onClick={() => setStep("query")}>
        <img src="/icons/back.svg" alt="back button" />
      </button>
    );
  }
}

function formatDate(dateStr: any) {
  const dateObj = new Date(dateStr);

  // Correcting the types of the options
  const options = { year: "numeric", month: "long", day: "numeric" };

  // @ts-ignore
  return dateObj.toLocaleDateString("en-US", options);
}

export default QueryReimbursement;
